const PatchCheck = () => {
    return <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="40" height="40" rx="20" fill="#E7DAFF" />
        <rect x="3" y="3" width="40" height="40" rx="20" stroke="#F7F2FF" strokeWidth="6" />
        <g clipPath="url(#clip0_2228_5708)">
            <path d="M20.5003 23.0003L22.167 24.667L25.917 20.917M20.6686 30.1677C20.9417 30.1316 21.2176 30.2057 21.4352 30.3733L22.4379 31.1427C22.7693 31.3973 23.2304 31.3973 23.5609 31.1427L24.6016 30.3436C24.796 30.1946 25.0414 30.1288 25.2839 30.1612L26.5857 30.3325C26.9995 30.3872 27.3986 30.1566 27.5587 29.7705L28.0596 28.5594C28.1531 28.3326 28.3328 28.153 28.5596 28.0594L29.7706 27.5585C30.1567 27.3993 30.3872 26.9993 30.3326 26.5854L30.1678 25.3308C30.1317 25.0577 30.2057 24.7818 30.3733 24.5642L31.1427 23.5614C31.3973 23.23 31.3973 22.7689 31.1427 22.4383L30.3437 21.3976C30.1946 21.2032 30.1289 20.9578 30.1613 20.7152L30.3326 19.4134C30.3872 18.9995 30.1567 18.6004 29.7706 18.4403L28.5596 17.9394C28.3328 17.8458 28.1531 17.6662 28.0596 17.4394L27.5587 16.2283C27.3995 15.8422 26.9995 15.6116 26.5857 15.6663L25.2839 15.8376C25.0414 15.8709 24.796 15.8052 24.6025 15.657L23.5619 14.858C23.2304 14.6033 22.7693 14.6033 22.4388 14.858L21.3981 15.657C21.2037 15.8052 20.9584 15.8709 20.7158 15.8394L19.4141 15.6681C19.0002 15.6135 18.6012 15.844 18.441 16.2301L17.941 17.4412C17.8466 17.6671 17.667 17.8468 17.4411 17.9412L16.2301 18.4412C15.844 18.6014 15.6134 19.0004 15.6681 19.4143L15.8393 20.7161C15.8708 20.9587 15.8051 21.2041 15.657 21.3976L14.8579 22.4383C14.6033 22.7698 14.6033 23.2309 14.8579 23.5614L15.657 24.6021C15.806 24.7966 15.8718 25.0419 15.8393 25.2845L15.6681 26.5863C15.6134 27.0002 15.844 27.3993 16.2301 27.5595L17.4411 28.0604C17.6679 28.1539 17.8475 28.3335 17.941 28.5604L18.4419 29.7714C18.6012 30.1575 19.0011 30.3881 19.415 30.3335L20.6686 30.1677Z" stroke="#8643FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_2228_5708">
                <rect width="20" height="20" fill="white" transform="translate(13 13)" />
            </clipPath>
        </defs>
    </svg>
}

export default PatchCheck;