const NoProduct = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" className={props?.className} width="95.001" height="79.33" viewBox="0 0 95.001 79.33">
        <g id="Fly" transform="translate(0 -0.016)">
            <path id="Path_1923" data-name="Path 1923" d="M113.852,155.343H44.45L54.486,98.43h69.4Z" transform="translate(-34.325 -75.997)" fill="#ffc727" />
            <path id="Path_1924" data-name="Path 1924" d="M112.264,72.56H89.549L88.51,78.453h24.307Z" transform="translate(-68.349 -56.02)" fill="#ffc727" />
            <g id="Group_10393" data-name="Group 10393" transform="translate(10.125 16.54)" opacity="0.1">
                <path id="Path_1925" data-name="Path 1925" d="M113.852,155.343H44.45L54.486,98.43h69.4Z" transform="translate(-44.45 -92.537)" />
                <path id="Path_1926" data-name="Path 1926" d="M112.264,72.56H89.549L88.51,78.453h24.307Z" transform="translate(-78.474 -72.56)" />
            </g>
            <path id="Path_1927" data-name="Path 1927" d="M309.114,160.162a4.366,4.366,0,0,1,0-1.982A4.367,4.367,0,0,1,309.114,160.162Z" transform="translate(-290.362 -141.218)" fill="#263238" />
            <path id="Path_1928" data-name="Path 1928" d="M297.273,163.633a3.873,3.873,0,0,1-.595-.8,3.954,3.954,0,0,1-.4-.911,3.775,3.775,0,0,1,.595.8A4.011,4.011,0,0,1,297.273,163.633Z" transform="translate(-280.54 -144.106)" fill="#263238" />
            <path id="Path_1929" data-name="Path 1929" d="M288.425,172.731a4.369,4.369,0,0,1-1.715-.991,3.909,3.909,0,0,1,.911.4A4,4,0,0,1,288.425,172.731Z" transform="translate(-273.15 -151.689)" fill="#263238" />
            <path id="Path_1930" data-name="Path 1930" d="M285.282,184.664a4.367,4.367,0,0,1-1.982,0A4.366,4.366,0,0,1,285.282,184.664Z" transform="translate(-270.517 -161.581)" fill="#263238" />
            <path id="Path_1931" data-name="Path 1931" d="M288.793,193.91a4.02,4.02,0,0,1-.8.595,3.909,3.909,0,0,1-.911.4,3.873,3.873,0,0,1,.8-.595A3.827,3.827,0,0,1,288.793,193.91Z" transform="translate(-273.436 -168.809)" fill="#263238" />
            <path id="Path_1932" data-name="Path 1932" d="M297.881,200.3a4.358,4.358,0,0,1-.991,1.717,3.833,3.833,0,0,1,.4-.911A3.941,3.941,0,0,1,297.881,200.3Z" transform="translate(-281.011 -173.744)" fill="#263238" />
            <path id="Path_1933" data-name="Path 1933" d="M309.812,202.51a3.974,3.974,0,0,1,.114.991,3.872,3.872,0,0,1-.114.991,3.777,3.777,0,0,1-.114-.991A3.977,3.977,0,0,1,309.812,202.51Z" transform="translate(-290.901 -175.45)" fill="#263238" />
            <path id="Path_1934" data-name="Path 1934" d="M319.06,199.985a3.87,3.87,0,0,1,.595.8,3.823,3.823,0,0,1,.4.911,4.358,4.358,0,0,1-.991-1.718Z" transform="translate(-298.131 -173.497)" fill="#263238" />
            <path id="Path_1935" data-name="Path 1935" d="M325.45,193.3a3.827,3.827,0,0,1,.911.4,3.873,3.873,0,0,1,.8.595,3.827,3.827,0,0,1-.911-.4A3.873,3.873,0,0,1,325.45,193.3Z" transform="translate(-303.065 -168.338)" fill="#263238" />
            <path id="Path_1936" data-name="Path 1936" d="M327.66,183.954a4.378,4.378,0,0,1,1.984,0A4.378,4.378,0,0,1,327.66,183.954Z" transform="translate(-304.772 -161.033)" fill="#263238" />
            <path id="Path_1937" data-name="Path 1937" d="M325.085,172.121a3.943,3.943,0,0,1,.8-.592,3.745,3.745,0,0,1,.911-.4,4.358,4.358,0,0,1-1.718.991Z" transform="translate(-302.78 -151.218)" fill="#263238" />
            <path id="Path_1938" data-name="Path 1938" d="M318.45,163.283a4,4,0,0,1,.4-.911,3.875,3.875,0,0,1,.595-.8,3.827,3.827,0,0,1-.4.911A3.873,3.873,0,0,1,318.45,163.283Z" transform="translate(-297.66 -143.836)" fill="#263238" />
            <path id="Path_1939" data-name="Path 1939" d="M79.427,162.347H9.875L0,107.5H69.55Z" transform="translate(0 -83.001)" fill="#ffc727" />
            <circle id="Ellipse_847" data-name="Ellipse 847" cx="2.891" cy="2.891" r="2.891" transform="translate(21.212 52.246)" fill="#263238" />
            <circle id="Ellipse_848" data-name="Ellipse 848" cx="2.891" cy="2.891" r="2.891" transform="translate(44.063 52.246)" fill="#263238" />
            <path id="Path_1940" data-name="Path 1940" d="M371.372,327.694l-.159-.3A5.508,5.508,0,0,0,366.7,324.6a3.572,3.572,0,0,0-3.435,2.711l-.082.33-.663-.159.08-.333a4.311,4.311,0,0,1,4.1-3.232,6.115,6.115,0,0,1,5.118,3.155l.159.3Z" transform="translate(-331.691 -269.205)" fill="#263238" />
            <path id="Path_1941" data-name="Path 1941" d="M555.61,323.962c.148-.032.3-.066.442-.1" transform="translate(-480.798 -269.158)" fill="none" stroke="#263238" strokeMiterlimit="10" strokeWidth="1" />
            <path id="Path_1942" data-name="Path 1942" d="M559.72,149.706c3.485-1.178,6.4-3.918,6.677-7.121.606-7.061-7.993-10.961-7.1-19.121.847-7.784,9.011-8.339,12.285-7.264,3.615,1.189,2.376,4.663-.9,4.214-5.674-.777-9.241-6.945-6.547-13.943,2.014-5.228,7.034-7.2,9.073-7.8" transform="translate(-483.591 -95.264)" fill="none" stroke="#263238" strokeMiterlimit="10" strokeWidth="1" strokeDasharray="4 4" />
            <path id="Path_1943" data-name="Path 1943" d="M622.52,97.792c.28-.073.442-.1.442-.1" transform="translate(-532.466 -94.507)" fill="none" stroke="#263238" strokeMiterlimit="10" strokeWidth="1" />
            <path id="Path_1944" data-name="Path 1944" d="M627.4,88.658c-.581-1.8-4.471-1.153-3.713,1.2S627.983,90.46,627.4,88.658Z" transform="translate(-533.294 -86.735)" fill="#263238" />
            <path id="Path_1945" data-name="Path 1945" d="M614.728,84.973c.03-.79,1.128-1.4,2.681-1.118a8.664,8.664,0,0,1,3.014,1.328,8.805,8.805,0,0,1-3.1,1.1C615.751,86.454,614.7,85.762,614.728,84.973Z" transform="translate(-526.449 -83.77)" fill="#ebebeb" />
            <path id="Path_1946" data-name="Path 1946" d="M623.359,89.3c-.806,0-1.61-.023-2.414-.048s-1.61-.066-2.415-.13c.806,0,1.613.021,2.417.046S622.557,89.232,623.359,89.3Z" transform="translate(-529.385 -87.889)" fill="#263238" />
            <path id="Path_1947" data-name="Path 1947" d="M621.58,85.91a6.778,6.778,0,0,1,1.287.8,6.063,6.063,0,0,1-.665-.364A6.5,6.5,0,0,1,621.58,85.91Z" transform="translate(-531.74 -85.41)" fill="#263238" />
            <path id="Path_1948" data-name="Path 1948" d="M626.333,89.57a7.4,7.4,0,0,1-1.333.861,7.4,7.4,0,0,1,1.339-.861Z" transform="translate(-534.381 -88.237)" fill="#263238" />
            <path id="Path_1949" data-name="Path 1949" d="M631.88,87.62a1.648,1.648,0,0,1,.588.474,1.561,1.561,0,0,1-.321-.2,1.666,1.666,0,0,1-.267-.271Z" transform="translate(-539.694 -86.731)" fill="#263238" />
            <path id="Path_1950" data-name="Path 1950" d="M620.589,97.681c.485.624,1.731.474,2.827-.661a8.767,8.767,0,0,0,1.677-2.841,8.776,8.776,0,0,0-3.164.911C620.559,95.866,620.1,97.055,620.589,97.681Z" transform="translate(-530.829 -91.797)" fill="#ebebeb" />
            <path id="Path_1951" data-name="Path 1951" d="M628.073,94.18c-.656.456-1.294.961-1.934,1.451s-1.266,1-1.879,1.517c.656-.456,1.294-.959,1.932-1.449S627.451,94.7,628.073,94.18Z" transform="translate(-533.81 -91.797)" fill="#263238" />
            <path id="Path_1952" data-name="Path 1952" d="M628.58,103.243a6.5,6.5,0,0,0,.326-.683,6.084,6.084,0,0,0,.246-.72,6.3,6.3,0,0,0-.326.683A6.493,6.493,0,0,0,628.58,103.243Z" transform="translate(-537.146 -97.712)" fill="#263238" />
            <path id="Path_1953" data-name="Path 1953" d="M628.595,99.6a7.532,7.532,0,0,0-1.595.084,6.652,6.652,0,0,0,.8,0A7.086,7.086,0,0,0,628.595,99.6Z" transform="translate(-535.926 -95.977)" fill="#263238" />
            <path id="Path_1954" data-name="Path 1954" d="M635.93,98.151a1.626,1.626,0,0,0,.141-.355,1.725,1.725,0,1,0-.141.355Z" transform="translate(-542.822 -94.299)" fill="#263238" />
            <path id="Path_1955" data-name="Path 1955" d="M637.288,87.64a.683.683,0,1,1,.44-.859A.683.683,0,0,1,637.288,87.64Z" transform="translate(-543.18 -85.717)" fill="#455a64" />
            <path id="Path_1956" data-name="Path 1956" d="M639.13,93.349a.683.683,0,1,1,.44-.861A.683.683,0,0,1,639.13,93.349Z" transform="translate(-544.602 -90.125)" fill="#455a64" />
        </g>
    </svg>
}

export default NoProduct;