
const Human = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="42" height="42" rx="21" fill="#E7DAFF" />
            <rect x="4" y="4" width="42" height="42" rx="21" stroke="#F3ECFF" stroke-width="7" />
            <path d="M31.125 22.375L28.5 19.75M28.5 19.75L31.125 17.125M28.5 19.75L33.75 19.75M28.5 32.875V31.825C28.5 30.3549 28.5 29.6198 28.2139 29.0583C27.9622 28.5643 27.5607 28.1628 27.0667 27.9111C26.5052 27.625 25.7701 27.625 24.3 27.625H20.45C18.9799 27.625 18.2448 27.625 17.6833 27.9111C17.1893 28.1628 16.7878 28.5643 16.5361 29.0583C16.25 29.6198 16.25 30.3549 16.25 31.825V32.875M25.4375 21.0625C25.4375 22.7539 24.0664 24.125 22.375 24.125C20.6836 24.125 19.3125 22.7539 19.3125 21.0625C19.3125 19.3711 20.6836 18 22.375 18C24.0664 18 25.4375 19.3711 25.4375 21.0625Z" stroke="#8643FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Human