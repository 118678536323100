import Cookies from 'js-cookie';

/**
    * @function setWithExpiry
    * @params key, value, ttl
    * @description used to set expiry time
*/
export const setWithExpiry = (key, value, ttl) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        const now = new Date();
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        };
        Cookies.set(key, JSON.stringify(item), {
            expires: 30
        });
    }
};

/**
    * @function getWithExpiry
    * @params key
    * @description used to get expiry time
*/
export const getWithExpiry = (key) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        const itemStr = Cookies.get(key);
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // if the item is expired, delete it from localstorage and return null
            Cookies.remove(key);
            return null;
        }
        // otherwise, return the value
        return item.value;
    }
};