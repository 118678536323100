import { Modal } from 'react-bootstrap';
import { MdContentCopy, MdOutlineFileDownload } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';

// Custom Component
import { PrimaryButton, PrimaryOutlineButton } from '../../buttons';

// Redux-Functions
import { setRecoveryCode, setRecoveryCodeModal, selectRecoveryCode } from '../../../../redux/userSlice';

const RecoveryCodeModal = (props) => {
    const dispatch = useDispatch();
    const code = useSelector(selectRecoveryCode);

    /**
        * @function handleCopy
        * @params
        * @description used to copy recovery code
    */
    const handleCopy = () => {
        navigator.clipboard.writeText(code)
    }

    /**
        * @function handleDownload
        * @params
        * @description used to download the recovery code
    */
    const handleDownload = () => {
        const blob = new Blob([code], { type: 'text/plain' })
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "accountrecovery.txt";
        link.href = url;
        link.click();
    }

    /**
        * @function handleClose
        * @params
        * @description used to close recovery code modal
    */
    const handleClose = () => {
        dispatch(setRecoveryCode())
        dispatch(setRecoveryCodeModal(false))
    }

    return <Modal show={props.show} onHide={handleClose} backdrop="static" size='md'>
        <Modal.Header className='fs-21 border-0 justify-content-center' closeButton>
            <span className='w-100 text-capitalize fw-bold'>One Time Account Recovery Code</span>
        </Modal.Header>
        <hr className='mx-3 mt-0' />
        <Modal.Body className='d-flex flex-column align-items-center py-0'>
            <div className='d-flex flex-column align-items-center bg-secondary bg-opacity-10 rounded p-3 w-100 mb-3'>
                <p className='text-danger fs-18 fw-bold'>This is your one-time recovery code</p>
                <p className='text-dark text-break text-center fs-30 fw-bold'>{code}</p>
            </div>
            <p className='secondary-text text-center mb-4'>Please store your recovery code in a safe place by choosing at least one of the option below.</p>
            <p className='secondary-text text-center'>Your recovery code can be used to access your account in the event that you cannot receive authentication codes.</p>
            <p className='secondary-text text-center'>For security purpose, your recovery code will not be shown to you again.</p>
        </Modal.Body>
        <Modal.Footer className='w-100 d-flex justify-content-between border-0'>
            <div className='d-flex'>
                <div className='me-3'>
                    <PrimaryButton className='px-4' onClick={handleDownload}>
                        <MdOutlineFileDownload size={20} className='me-1' />
                        Download
                    </PrimaryButton>
                </div>
                <div>
                    <PrimaryOutlineButton className='px-4' onClick={handleCopy}>
                        <MdContentCopy size={20} className='me-1' />
                        Copy
                    </PrimaryOutlineButton>
                </div>
            </div>
            <div>
                <PrimaryButton className='px-4' onClick={handleClose}>
                    Done
                </PrimaryButton>
            </div>
        </Modal.Footer>
    </Modal>
}

export default RecoveryCodeModal