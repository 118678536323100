// Loader
import whiteAnimationData from "../assets/loader/loader.json";
import darkAnimationData from "../assets/loader/dark-loader.json";

export const animationOptions = (theme) => {
    return {
        loop: true,
        autoplay: true,
        animationData: theme === 'light' ? whiteAnimationData : darkAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
}