const Announce = (props) => {
    return <svg width="16px" height="16px" role={props?.role} className={props?.className} viewBox="0 0 475 420" fill="none" xmlns="http://www.w3.org/2000/svg" id="SegWitz-8965-0" color="var(--button-color)" >
        <path d="M4.38165 314.615C-12.0184 265.263 21.5483 247.442 40.3816 244.7L73.8816 330.551C57.5483 345.802 16.8816 345.973 4.38165 314.615Z" fill="#673E98"></path>
        <path d="M200.381 379.388C200.781 402.419 187.548 416.059 180.881 420C164.714 404.406 130.181 371.06 121.381 362.424C110.381 351.628 108.381 358.825 100.381 358.825C77.9809 342.375 68.7142 325.239 66.8809 318.727L83.3809 296.622C106.548 295.936 152.081 295.696 148.881 300.22C145.681 304.744 144.214 312.73 143.881 316.157C146.548 317.185 153.581 320.681 160.381 326.438C161.581 346.179 187.548 369.963 200.381 379.388Z" fill="#673E98"></path>
        <path d="M33.8824 294.566C24.3823 260.637 33.3825 247.559 46.8824 239.56C56.7158 233.734 80.9825 220.847 97.3825 211.8C117.882 200.49 162.844 153.624 179.882 115.667C194.882 82.2523 224.882 78.1399 276.882 178.385C289.682 203.06 294.382 223.109 294.382 242.13C294.382 283.256 300.382 283.77 273.882 304.847C239.882 294.566 200.382 300.221 172.382 300.221C144.382 300.221 136.882 308.446 125.382 306.904C113.882 305.362 115.882 305.876 102.882 312.045C89.8825 318.213 53.8824 346.488 33.8824 294.566Z" fill="#673E98"></path>
        <path d="M82.8809 309.474C55.2809 323.045 45.3809 301.763 43.8809 289.425L48.3809 292.509C73.8809 281.028 136.281 252.925 181.881 232.362C177.481 226.193 177.048 209.914 177.381 202.546C180.214 217.454 191.681 254.365 214.881 282.742C197.881 280.171 155.881 289.425 140.881 296.108C125.881 302.791 117.381 292.509 82.8809 309.474Z" fill="black" fillOpacity="0.1"></path>
        <path d="M9.88086 305.361L8.38086 319.241C20.3809 342.889 46.8809 340.832 63.3809 335.692C38.5809 329.523 27.0475 306.389 26.3809 298.164L9.88086 305.361Z" fill="black" fillOpacity="0.1"></path>
        <path d="M123.381 314.101C118.981 312.044 114.548 314.272 112.881 315.643C124.081 318.11 115.881 337.577 110.381 347.001H117.381C119.048 343.917 123.081 335.795 125.881 327.981C129.381 318.213 132.881 322.84 135.381 324.382C133.381 319.858 135.214 315.643 136.381 314.101C133.881 314.957 127.781 316.157 123.381 314.101Z" fill="#1C1C1C" fillOpacity="0.05"></path>
        <path d="M144.381 353.684C146.381 351.216 153.881 340.661 157.381 335.691C158.181 348.029 184.381 371.334 197.381 381.444C196.715 384.7 194.681 392.445 191.881 397.381C184.281 396.147 174.715 385.557 170.881 380.416C165.215 373.562 153.081 359.442 149.881 357.797C145.881 355.74 141.881 356.769 144.381 353.684Z" fill="#1C1C1C" fillOpacity="0.05"></path>
        <ellipse rx="53.6063" ry="116.121" transform="matrix(0.90684 -0.421475 0.402485 0.915426 240.609 199.837)" fill="#673E98"></ellipse>
        <path d="M198.381 103.843C181.181 112.069 186.048 170.159 192.381 192.779C195.214 191.75 214.781 183.217 234.381 177.87C260.781 170.056 272.381 194.835 274.881 208.201C275.714 208.372 278.181 208.304 281.381 206.659C287.381 218.585 295.548 237.332 298.881 245.214C292.214 226.022 276.281 182.6 265.881 162.448C252.881 137.258 219.881 93.562 198.381 103.843Z" fill="#673E98"></path>
        <path d="M237.881 187.638C223.481 191.75 205.214 198.262 197.881 201.004C196.214 201.86 192.281 203.677 189.881 204.088C192.281 217.248 209.881 247.956 218.381 261.665C228.048 256.01 249.981 243.261 260.381 237.503C273.381 230.306 273.381 218.996 267.881 204.088C262.381 189.18 255.881 182.497 237.881 187.638Z" fill="#673E98"></path>
        <path d="M248.883 193.807C244.083 195.04 245.549 204.602 246.883 209.229C248.549 208.201 252.483 206.042 254.883 205.63C257.883 205.116 262.383 209.229 265.883 221.053C265.383 205.63 254.883 192.264 248.883 193.807Z" fill="#6F6F6F" fillOpacity="0.42"></path>
        <path d="M258.881 231.848C242.081 220.744 241.881 202.203 243.881 194.321C237.081 194.321 238.381 206.658 239.881 212.827C230.715 215.569 212.081 221.978 210.881 225.679C208.481 233.493 218.548 250.183 223.881 257.552C229.215 252.925 243.681 241.307 258.881 231.848Z" fill="black" fillOpacity="0.1"></path>
        <path d="M250.881 59.1188L286.381 4.62671L272.881 48.3232H290.381L259.381 101.273L272.881 59.1188H250.881Z" fill="#673E98"></path>
        <path d="M305.62 70.3603L383.437 22.0233L340.362 70.0692L362.958 80.9258L291.856 130.063L334.026 84.0085L305.62 70.3603Z" fill="#673E98"></path>
        <path d="M358.928 121.626L468.791 91.0959L400.827 133.61L424.368 154.696L322.315 188.573L388.522 148.133L358.928 121.626Z" fill="#673E98"></path>
        <path d="M414.381 199.461L335.381 215.398H383.881L375.381 227.736L468.881 229.792L406.381 215.398L414.381 199.461Z" fill="#673E98"></path>
        <path d="M406.381 274.002L335.381 256.524L375.381 274.002L364.881 283.256L438.381 317.185L393.881 283.256L406.381 274.002Z" fill="#673E98">
        </path>
    </svg>
}

export default Announce;