const Reactiveafter24icon = () => {
    return (
        <svg width="144" height="112" viewBox="0 0 144 112" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.1906 106.241C35.9984 106.241 46.3812 95.8899 46.3812 83.1207C46.3812 70.3515 35.9984 60 23.1906 60C10.3828 60 0 70.3515 0 83.1207C0 95.8899 10.3828 106.241 23.1906 106.241Z" fill="#EFEFEF" />
            <path d="M109.542 67.9097C128.29 67.9097 143.489 52.7566 143.489 34.0643C143.489 15.3719 128.29 0.21875 109.542 0.21875C90.7927 0.21875 75.5938 15.3719 75.5938 34.0643C75.5938 52.7566 90.7927 67.9097 109.542 67.9097Z" fill="#5A6378" />
            <path d="M70.6576 5.125C61.0273 14.5455 45.6604 22.2661 29.2166 25.5554C29.2117 26.5584 29.1845 32.1149 29.0592 57.7261C28.8919 91.891 64.4969 108.586 70.0245 111.149C75.3804 108.897 111.435 92.228 111.602 58.063C111.727 32.4519 111.754 26.8953 111.759 25.8924C95.3483 22.4689 80.1953 14.6238 70.6576 5.125Z" fill="white" />
            <path d="M70.571 8.83105C60.6432 17.1478 46.8893 23.6846 31.765 27.2475L31.6158 57.7208C31.5475 71.6644 37.6133 84.2783 49.6455 95.2138C57.5511 102.399 65.8632 106.855 70.081 108.859C70.2623 108.775 70.4613 108.68 70.6573 108.587V8.902C70.6288 8.87826 70.5989 8.8552 70.571 8.83105Z" fill="#9963FC" />
            <path d="M70.6562 8.90234V108.587C75.0421 106.504 83.0141 102.197 90.6484 95.3816C102.786 84.5448 108.976 71.9807 109.044 58.0371L109.193 27.5634C94.1473 23.8882 80.4951 17.2659 70.6562 8.90234Z" fill="#8643FF" />
            <path d="M100.962 39.4463C89.4479 41.0678 73.769 50.6577 63.8777 62.1893C62.4904 56.7521 56.2619 51.7884 53.0033 50.7033L43.1309 59.7651C49.2951 62.302 56.0312 69.4897 56.9242 76.8481L66.7864 78.2438C70.9446 70.7433 81.6831 52.802 103.047 43.155C102.636 41.6865 101.127 39.6558 100.962 39.4463Z" fill="white" />
        </svg>
    )
}

export default Reactiveafter24icon