const Share = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" className={props?.className} viewBox="0 0 15 15">
        <g id="Share" transform="translate(-2603 -1442)">
            <rect id="Rectangle_3369" data-name="Rectangle 3369" width="15" height="15" transform="translate(2603 1442)" fill="none" />
            <g id="Share-2" data-name="Share" transform="translate(2603.36 1443.54)">
                <path id="Path_1265" data-name="Path 1265" d="M.477,12.014H.493a.4.4,0,0,0,.386-.4c.015-.161.457-4.049,6.679-4.044l.014,2.486a.432.432,0,0,0,.254.392.45.45,0,0,0,.472-.057l5.76-4.653a.427.427,0,0,0,0-.671L8.3.461A.456.456,0,0,0,7.825.406.434.434,0,0,0,7.571.8L7.558,3.229A7.814,7.814,0,0,0,1.682,5.6,8.248,8.248,0,0,0,.066,11.646.413.413,0,0,0,.477,12.014ZM8.016,6.7h0C3.57,6.7,1.872,8.167.9,9.46a6.307,6.307,0,0,1,1.458-3.3C3.558,4.8,5.488,4.088,8.016,4.088a.438.438,0,0,0,.444-.433V1.709l4.614,3.7L8.46,9.143V7.134a.426.426,0,0,0-.131-.307A.451.451,0,0,0,8.016,6.7Z" transform="translate(-0.006 -0.364)" fill="currentcolor" fillRule="evenodd" />
                <rect id="Rectangle_2118" data-name="Rectangle 2118" width="13.829" height="11.921" transform="translate(0)" fill="none" />
            </g>
        </g>
    </svg>
}

export default Share;