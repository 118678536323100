import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    countryList: []
};

export const countrySlice = createSlice({
    name: "Country",
    initialState,
    reducers: {
        setCountryList: (state, action) => {
            state.countryList = action.payload;
        }
    }
});

export const {
    setCountryList
} = countrySlice.actions;

export const selectCountryList = (state) => state.country.countryList;

export default countrySlice.reducer;