import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    industries: [],
    userIndustries: [],
    userNotFollowIndustries: []
};

export const industrySlice = createSlice({
    name: "Industry",
    initialState,
    reducers: {
        setIndustries: (state, action) => {
            state.industries = action.payload;
        },
        setUserIndustries: (state, action) => {
            state.userIndustries = action.payload;
        },
        setUserNotFollowIndustries: (state, action) => {
            state.userNotFollowIndustries = action.payload;
        }
    }
});

export const {
    setIndustries,
    setUserIndustries,
    setUserNotFollowIndustries
} = industrySlice.actions;

export const selectIndustries = (state) => state.industry.industries;
export const selectUserIndustries = (state) => state.industry.userIndustries;
export const selectUserNotFollowIndustries = (state) => state.industry.userNotFollowIndustries;

export default industrySlice.reducer;