import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categories: null
};

export const categorySlice = createSlice({
    name: "Category",
    initialState,
    reducers: {
        setCategories: (state, action) => {
            state.categories = action.payload;
        }
    }
});

export const {
    setCategories
} = categorySlice.actions;

export const selectCategories = (state) => state.category.categories;

export default categorySlice.reducer;