import { configureStore } from "@reduxjs/toolkit";

// Slice
import categoryReducer from "./categorySlice";
import countryReducer from "./countrySlice";
import dashboardReducer from "./dashboardSlice";
import igniteReducer from "./igniteSlice";
import industryReducer from "./industrySlice";
import searchReducer from "./searchSlice";
import userReducer from "./userSlice";

export default configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: {
            warnAfter: 360
        },
        serializableCheck: {
            warnAfter: 360
        }
    }),
    reducer: {
        category: categoryReducer,
        country: countryReducer,
        dashboard: dashboardReducer,
        ignite: igniteReducer,
        industry: industryReducer,
        search: searchReducer,
        user: userReducer
    }
});