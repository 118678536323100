import { useState, useEffect } from 'react';
import { compare } from 'compare-versions';
import { useLocation } from 'react-router-dom';

// Utility Service
import { getWithExpiry, setWithExpiry } from '../utils/localStorageTTL';
import packageJson from '../../package.json';

global.appVersion = packageJson.version;
const useHandleCache = () => {
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [isLatestVersionAvailable, setIsLatestVersionAvailable] = useState(false);

    /**
        * @function clearCacheAndReload
        * @params latestVersion
        * @description used to clear cache & reload
    */
    const clearCacheAndReload = (latestVersion) => {
        const expiry = getWithExpiry("adv")
        if (expiry === null) {
            if (caches) {
                caches.keys().then(function (names) {
                    for (let name of names) caches.delete(name);
                });
            }
            if (latestVersion) {
                setWithExpiry('adv', latestVersion, 86400 * 1000);
            }
            window.location.reload(true);
            setIsLoading(false)
            setIsLatestVersionAvailable(true)
        } else {
            if (latestVersion) {
                setWithExpiry('adv', latestVersion, 86400 * 1000);
            }
            window.location.reload(true);
            setIsLoading(false)
            setIsLatestVersionAvailable(true)
        }
    };

    const checkCacheStatus = async () => {
        try {
            const res = await fetch(`/meta.json`);
            const meta = await res.json();
            const latestVersion = meta.version;
            const currentVersion = global.appVersion;
            const expiry = getWithExpiry("adv")
            let shouldForceRefresh;
            if (expiry) {
                shouldForceRefresh = compare(expiry, currentVersion, '=');
            } else {
                shouldForceRefresh = compare(latestVersion, currentVersion, '=');
            }
            if (!expiry) {
                setWithExpiry('adv', latestVersion, 86400 * 1000);
            }
            console.log('shouldForceRefresh, expiry currentVersion latestVersion')
            console.log(shouldForceRefresh, expiry, currentVersion, latestVersion)
            if (!shouldForceRefresh) {
                if (expiry !== null) {
                    clearCacheAndReload(currentVersion)
                    setIsLoading(false);
                    setIsLatestVersionAvailable(false);
                }
                else {
                    setWithExpiry('adv', latestVersion, 86400 * 1000);
                    console.log(`New version - ${latestVersion} is available. Need to force refresh.`);
                    window.location.reload();
                    setIsLoading(false);
                    setIsLatestVersionAvailable(false);
                }
            } else {
                if (currentVersion & latestVersion && !compare(latestVersion, currentVersion, '=')) {
                    window.location.reload();
                }
                console.log(`Already the latest version - ${latestVersion}. No refresh required.`);
                setIsLoading(false);
                setIsLatestVersionAvailable(true);
            }
        } catch (error) {
            setIsLoading(false)
            setIsLatestVersionAvailable(false)
        }
    }

    useEffect(() => {
        checkCacheStatus()
        // eslint-disable-next-line
    }, [location?.pathname])

    return { isLoading, isLatestVersionAvailable, clearCacheAndReload };
};

export default useHandleCache;