const Dislike = (props) => {
    return <svg role='button' width={props?.width} height={props?.height} className={props?.className} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props?.onClick}>
        <g clipPath="url(#clip0_4584_3901)">
            <path d="M12.9179 1.58504C12.5429 1.17904 11.3088 1.75742 9.96482 0.819419C8.71482 0.210419 5.08982 0.491044 3.96482 0.710044C2.83982 0.929044 2.26495 1.91029 2.91795 2.49129C2.74595 2.66329 1.30875 2.91329 2.21775 4.36629C1.98642 4.44761 1.78626 4.59785 1.64451 4.79793C1.50275 4.99802 1.42674 5.23827 1.42674 5.48348C1.42674 5.72869 1.50275 5.96699 1.64451 6.16707C1.78626 6.36716 1.98642 6.51935 2.21775 6.60067C1.75475 7.21367 1.62469 8.56967 3.17869 8.60067C3.40669 8.64767 3.921 8.60067 4.749 8.60067C5.40281 8.51929 6.06394 8.51929 6.71775 8.60067C6.98375 8.67867 5.39025 11.5698 6.90525 13.0538C8.26425 14.0068 8.205 12.1945 9.124 10.6475C9.89184 9.10296 11.2227 7.91167 12.8428 7.31942C13.8858 6.98542 13.8079 2.55004 12.9179 1.58504Z" fill={props?.disliked ? "currentcolor" : "none"} stroke="currentcolor" strokeWidth="0.9" />
        </g>
        <defs>
            <clipPath id="clip0_4584_3901">
                <rect width="15" height="13.955" fill={props?.disliked ? "currentcolor" : "none"} stroke={props?.disliked ? "none" : "currentcolor"} />
            </clipPath>
        </defs>
    </svg>
}

export default Dislike;