import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";

// bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';

// flag-icons css
import "/node_modules/flag-icons/css/flag-icons.min.css";

// datepicker css
import "react-datepicker/dist/react-datepicker.css";

// react-image-crop css
import 'react-image-crop/dist/ReactCrop.css';

// react-loading-skeleton css
import 'react-loading-skeleton/dist/skeleton.css';

// react-phone-number-input css
import 'react-phone-number-input/style.css';

// react-image-lightbox css
import 'react-18-image-lightbox/style.css';

// swiper css
import 'swiper/css';
import 'swiper/css/pagination';

import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Redux Store
import store from "./redux/store";

// SCSS
import './assets/scss/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
)

serviceWorkerRegistration.register({
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", event => {
                if (event.target.state === "activated") {
                    window.location.reload()
                }
            })
            waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
        }
    }
})

// serviceWorkerRegistration.register({
//     onUpdate: registration => {
//         if (registration && registration.waiting) {
//             registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//         }
//         window.location.reload();
//     }
// })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
