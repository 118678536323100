import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    hottestBrands: null,
    recommenededBrandsByUser: null,
    mostDiscussedBrands: null,
    allPopularProducts: null,
    allPopularProductsBrandByIds: null,
    brandReview: null,
    productReview: null,
    allProductComparsions: null,
    allProductComparsionsBrandByIds: null,
    ignitesByBrandId: null
};

export const dashboardSlice = createSlice({
    name: "Dashboard",
    initialState,
    reducers: {
        setHottestBrands: (state, action) => {
            state.hottestBrands = action.payload;
        },
        setRecommenededBrandsByUser: (state, action) => {
            state.recommenededBrandsByUser = action.payload;
        },
        setMostDiscussedBrands: (state, action) => {
            state.mostDiscussedBrands = action.payload;
        },
        setAllPopularProducts: (state, action) => {
            state.allPopularProducts = action.payload;
        },
        setAllPopularProductsBrandByIds: (state, action) => {
            state.allPopularProductsBrandByIds = action.payload;
        },
        setBrandReview: (state, action) => {
            state.brandReview = action.payload;
        },
        setProductReview: (state, action) => {
            state.productReview = action.payload;
        },
        setAllProductComparsions: (state, action) => {
            state.allProductComparsions = action.payload;
        },
        setAllProductComparsionsBrandByIds: (state, action) => {
            state.allProductComparsionsBrandByIds = action.payload;
        },
        setIgnitesByBrandId: (state, action) => {
            state.ignitesByBrandId = action.payload;
        }
    }
});

export const {
    setHottestBrands,
    setRecommenededBrandsByUser,
    setMostDiscussedBrands,
    setAllPopularProducts,
    setAllPopularProductsBrandByIds,
    setBrandReview,
    setProductReview,
    setAllProductComparsions,
    setAllProductComparsionsBrandByIds,
    setIgnitesByBrandId,
} = dashboardSlice.actions;

export const selectHottestBrands = (state) => state.dashboard.hottestBrands;
export const selectRecommenededBrandsByUser = (state) => state.dashboard.recommenededBrandsByUser;
export const selectMostDiscussedBrands = (state) => state.dashboard.mostDiscussedBrands;
export const selectAllPopularProducts = (state) => state.dashboard.allPopularProducts;
export const selectAllPopularProductsBrandByIds = (state) => state.dashboard.allPopularProductsBrandByIds;
export const selectBrandReview = (state) => state.dashboard.brandReview;
export const selectProductReview = (state) => state.dashboard.productReview;
export const selectAllProductComparsions = (state) => state.dashboard.allProductComparsions;
export const selectAllProductComparsionsBrandByIds = (state) => state.dashboard.allProductComparsionsBrandByIds;
export const selectIgnitesByBrandId = (state) => state.dashboard.ignitesByBrandId;

export default dashboardSlice.reducer;