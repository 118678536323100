import React from 'react'

const Reactivewith24icon = () => {
    return (
        <svg width="148" height="106" viewBox="0 0 148 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M113.542 67.9097C132.29 67.9097 147.489 52.7566 147.489 34.0643C147.489 15.3719 132.29 0.21875 113.542 0.21875C94.7927 0.21875 79.5938 15.3719 79.5938 34.0643C79.5938 52.7566 94.7927 67.9097 113.542 67.9097Z" fill="#5A6378" />
            <path d="M23.3078 106C36.1156 106 46.4984 95.6487 46.4984 82.8795C46.4984 70.1103 36.1156 59.7588 23.3078 59.7588C10.5 59.7588 0.117188 70.1103 0.117188 82.8795C0.117188 95.6487 10.5 106 23.3078 106Z" fill="#EFEFEF" />
            <path d="M92.836 27.7521V52.0268H85.9556V27.7521C85.9556 18.5216 78.9228 11.0149 70.2725 11.0149C61.6207 11.0149 54.5894 18.5216 54.5894 27.7521V52.0268H47.709V27.7521C47.709 14.5323 57.8303 3.78125 70.2725 3.78125C82.7132 3.78125 92.836 14.5323 92.836 27.7521Z" fill="#C7C7C7" />
            <path d="M47.709 34.0654L54.5894 34.3415V52.0282H47.709V34.0654Z" fill="#A6A6A6" />
            <path d="M92.8374 35.8967V52.0277H85.957V35.6162L92.8374 35.8967Z" fill="#A6A6A6" />
            <path d="M101.657 38.2051H38.8926V94.9755H101.657V38.2051Z" fill="#8643FF" />
            <path opacity="0.1" d="M101.66 38.2051H70.2773V94.9755H101.66V38.2051Z" fill="black" />
            <path d="M72.9311 68.6695L75.5581 81.096H64.4082L67.0352 68.6695C65.4646 67.6791 64.4082 65.9539 64.4082 63.9732C64.4082 60.8749 66.9078 58.415 69.9832 58.415C73.0586 58.415 75.5581 60.8749 75.5581 63.9732C75.5581 65.9539 74.5017 67.6776 72.9311 68.6695Z" fill="white" />
        </svg>

    )
}

export default Reactivewith24icon