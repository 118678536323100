const NoItemImage = () => {
    return <svg className="img-fluid" width="399" height="263" viewBox="0 0 399 263" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.07" d="M246.603 43.518H77.972C72.2011 43.518 66.6666 41.2255 62.586 37.1449C58.5054 33.0643 56.213 27.5298 56.213 21.759C56.213 15.9882 58.5054 10.4537 62.586 6.37306C66.6666 2.29246 72.2011 0 77.972 0H246.603C252.374 0 257.908 2.29246 261.989 6.37306C266.069 10.4537 268.362 15.9882 268.362 21.759C268.362 27.5298 266.069 33.0643 261.989 37.1449C257.908 41.2255 252.374 43.518 246.603 43.518Z" fill="#9C9C9C" />
        <path opacity="0.07" d="M306.44 219.402H77.972C72.2011 219.402 66.6666 217.11 62.586 213.029C58.5054 208.948 56.213 203.414 56.213 197.643C56.213 191.872 58.5054 186.338 62.586 182.257C66.6666 178.176 72.2011 175.884 77.972 175.884H306.44C312.211 175.884 317.745 178.176 321.826 182.257C325.906 186.338 328.199 191.872 328.199 197.643C328.199 203.414 325.906 208.948 321.826 213.029C317.745 217.11 312.211 219.402 306.44 219.402Z" fill="#9C9C9C" />
        <path opacity="0.07" d="M291.934 262.92H132.369C126.598 262.92 121.064 260.628 116.983 256.547C112.902 252.466 110.61 246.932 110.61 241.161C110.61 235.39 112.902 229.856 116.983 225.775C121.064 221.694 126.598 219.402 132.369 219.402H291.934C297.705 219.402 303.239 221.694 307.32 225.775C311.401 229.856 313.693 235.39 313.693 241.161C313.693 246.932 311.401 252.466 307.32 256.547C303.239 260.628 297.705 262.92 291.934 262.92Z" fill="#9C9C9C" />
        <path opacity="0.07" d="M330.01 87.849H103.355C97.5841 87.849 92.0496 85.5565 87.969 81.4759C83.8884 77.3953 81.5959 71.8608 81.5959 66.09C81.5959 60.3191 83.8884 54.7847 87.969 50.7041C92.0496 46.6235 97.5841 44.331 103.355 44.331H330.01C335.781 44.331 341.315 46.6235 345.396 50.7041C349.476 54.7847 351.769 60.3191 351.769 66.09C351.769 71.8608 349.476 77.3953 345.396 81.4759C341.315 85.5565 335.781 87.849 330.01 87.849Z" fill="#9C9C9C" />
        <path opacity="0.07" d="M306.438 132.367H21.759C15.9882 132.367 10.4537 130.075 6.37306 125.994C2.29246 121.913 0 116.379 0 110.608C0 104.837 2.29246 99.3027 6.37306 95.2221C10.4537 91.1415 15.9882 88.849 21.759 88.849H306.438C312.209 88.849 317.743 91.1415 321.824 95.2221C325.905 99.3027 328.197 104.837 328.197 110.608C328.197 116.379 325.905 121.913 321.824 125.994C317.743 130.075 312.209 132.367 306.438 132.367Z" fill="#9C9C9C" />
        <path opacity="0.07" d="M377.154 175.885H103.355C97.5841 175.885 92.0496 173.593 87.969 169.512C83.8884 165.431 81.5959 159.897 81.5959 154.126C81.5959 148.355 83.8884 142.821 87.969 138.74C92.0496 134.659 97.5841 132.367 103.355 132.367H377.155C382.926 132.367 388.46 134.659 392.541 138.74C396.621 142.821 398.914 148.355 398.914 154.126C398.914 159.897 396.621 165.431 392.541 169.512C388.46 173.593 382.925 175.885 377.154 175.885Z" fill="#9C9C9C" />
        <path d="M237.108 139.127L225 158.5L250.392 174.873L262.5 155.5L237.108 139.127Z" fill="#8643FF" fillOpacity="0.35" stroke="#8643FF" strokeWidth="7" />
        <path d="M306.485 196.078L306.373 196.26C303.294 201.192 296.918 204.248 293.217 201.939L251.393 175.571L263.213 156.023L305.822 182.417C309.521 184.721 309.563 191.145 306.485 196.078Z" fill="#8643FF" fillOpacity="0.35" stroke="#8643FF" strokeWidth="7" />
        <path d="M224.698 158.698C246.365 137.03 246.365 101.9 224.698 80.2326C203.03 58.5651 167.9 58.5651 146.233 80.2326C124.565 101.9 124.565 137.03 146.233 158.698C167.9 180.365 203.03 180.365 224.698 158.698Z" stroke="#8643FF" strokeWidth="10" />
        <g opacity="0.05">
            <path d="M151.071 117.542C150.873 110.081 153.164 102.765 157.583 96.7499C162.002 90.7343 168.298 86.3606 175.477 84.3183C182.656 82.2759 190.311 82.6812 197.235 85.4701C204.158 88.2591 209.957 93.273 213.716 99.7215C215.889 103.426 221.794 100.332 219.62 96.6202C215.298 89.2323 208.762 83.3913 200.937 79.9244C193.111 76.4574 184.393 75.5403 176.018 77.3029C159.121 80.8998 146.65 95.4218 144.62 112.399C144.436 114.042 144.362 115.696 144.4 117.349C144.465 121.63 151.128 121.856 151.066 117.542H151.071Z" fill="#B6B2B2" />
            <path d="M221.435 114.362C223.861 114.322 225.794 112.323 225.754 109.898C225.714 107.472 223.715 105.538 221.289 105.579C218.864 105.619 216.93 107.618 216.97 110.043C217.011 112.469 219.009 114.403 221.435 114.362Z" fill="#B6B2B2" />
        </g>
        <path d="M169 105L201 137M201 105L169 137" stroke="#AE81FF" strokeWidth="7" strokeLinecap="round" />
    </svg>
}

export default NoItemImage;