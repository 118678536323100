import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

// Custom Component
import { PrimaryButton, SecondaryButton } from '../../buttons';

// Images
import { Reactiveafter24icon } from '../../../../assets/images/svg';

// Redux-Functions
import { setReactivateWelcomeModal } from '../../../../redux/userSlice';

const ReactiveWelcomeModal = (props) => {
    const dispatch = useDispatch();

    /**
        * @function handleClose
        * @params
        * @description used to close reactive welcome modal
    */
    const handleClose = () => {
        dispatch(setReactivateWelcomeModal(false))
    }

    return <Modal show={props.show} onHide={handleClose} backdrop="static" size='md'>
        <Modal.Header className='fs-21 border-0 justify-content-center' closeButton>
            <span className='w-100 text-capitalize fw-bold'>Reactivate Account</span>
        </Modal.Header>
        <hr className='mx-3 mt-0' />
        <Modal.Body className='d-flex flex-column justify-content-center align-items-center py-0'>
            <div className="py-5" >
                <Reactiveafter24icon />
            </div>
            <p className='fw-bold fs-20 text-center text-dark mb-0'>Welcome back!</p>
            <p className='fw-bold fs-20 text-center text-dark'>Bring the fire back by reactivating your account today.</p>
            <p className='text-center text-secondary'>You are reactivating your account from deactivation by logging in.</p>
        </Modal.Body>
        <Modal.Footer className='border-0'>
            <div>
                <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
            </div>
            <div>
                <PrimaryButton onClick={handleClose}>Let's go</PrimaryButton>
            </div>
        </Modal.Footer>
    </Modal>
}

export default ReactiveWelcomeModal;