import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    likeIgnite: [],
    commentIgnite: [],
    reviewsIgnite: []
};

export const igniteSlice = createSlice({
    name: "Ignite",
    initialState,
    reducers: {
        setLikeIgnite: (state, action) => {
            state.likeIgnite = action.payload;
        },
        setCommentIgnite: (state, action) => {
            state.commentIgnite = action.payload;
        },
        setReviewsIgnite: (state, action) => {
            state.reviewsIgnite = action.payload;
        }
    }
});

export const {
    setLikeIgnite,
    setCommentIgnite,
    setReviewsIgnite
} = igniteSlice.actions;

export const selectLikeIgnite = (state) => state.ignite.likeIgnite;
export const selectCommentIgnite = (state) => state.ignite.commentIgnite;
export const selectReviewsIgnite = (state) => state.ignite.reviewsIgnite;

export default igniteSlice.reducer;