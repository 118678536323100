import { Button, Spinner } from 'react-bootstrap';

export const PrimaryButton = (props) => {
    return <Button className={`w-100 fw-600${props.className ? ` ${props.className}` : ''}`} variant="primary" type={props.type && props.type} onClick={props.onClick} disabled={props.disabled}>
        {props?.loading && <Spinner className='me-2' animation="border" size="sm" />}
        {props.children}
    </Button>
};

export const PrimaryOutlineButton = (props) => {
    return <Button className={`w-100 fw-600${props.className ? ` ${props.className}` : ''}`} variant="outline-primary" type={props.type && props.type} onClick={props.onClick} disabled={props.disabled}>
        {props?.loading && <Spinner className={props?.brandFollow ? 'mx-1' : 'me-2'} animation="border" size="sm" />}
        {props.children}
    </Button>;
};

export const SecondaryButton = (props) => {
    return <Button className={`w-100 text-white fw-600${props.className ? ` ${props.className}` : ''}`} variant="secondary" type={props.type && props.type} onClick={props.onClick} disabled={props.disabled}>
        {props?.loading && <Spinner className='me-2' animation="border" size="sm" />}
        {props.children}
    </Button>;
};

export const SuccessButton = (props) => {
    return <Button className={`w-100 text-white fw-600${props.className ? ` ${props.className}` : ''}`} variant="success" type={props.type && props.type} onClick={props.onClick} disabled={props.disabled}>
        {props?.loading && <Spinner className='me-2' animation="border" size="sm" />}
        {props.children}
    </Button>;
};

export const DangerButton = (props) => {
    return <Button className={`w-100 text-white fw-600${props.className ? ` ${props.className}` : ''}`} variant="danger" type={props.type && props.type} onClick={props.onClick} disabled={props.disabled}>
        {props?.loading && <Spinner className='me-2' animation="border" size="sm" />}
        {props.children}
    </Button>;
};

export const DangerOutlineButton = (props) => {
    return <Button className={`w-100 fw-600${props.className ? ` ${props.className}` : ''}`} variant="outline-danger" type={props.type && props.type} onClick={props.onClick} disabled={props.disabled}>
        {props?.loading && <Spinner className='me-2' animation="border" size="sm" />}
        {props.children}
    </Button>;
};

export const IgniteButton = (props) => {
    return <Button className={`w-100 btn-outline-ignite fw-600${props.className ? ` ${props.className}` : ''}`} type={props.type && props.type} onClick={props.onClick} disabled={props.disabled}>
        {props?.loading && <Spinner className='me-2' animation="border" size="sm" />}
        {props.children}
    </Button>;
};