import Cookies from 'js-cookie';

// save the token and user into the Cookies
export const setUserSession = (token, user) => {
    Cookies.set('adu', JSON.stringify(user), {
        expires: 14
    });
    Cookies.set('adact', token, {
        expires: 14
    });
}

// save the token into the Cookies
export const setCookieToken = (token) => {
    Cookies.set('adact', token, {
        expires: 14
    });
}

// save the theme into the Cookies
export const setCookieTheme = (theme) => {
    Cookies.set('adtp', theme, {
        expires: 14
    });
}

// update user session
export const updateUserSession = (user) => {
    Cookies.set('adu', JSON.stringify(user), {
        expires: 14
    });
}

// return the user data from the Cookies
export const getUser = () => {
    const userStr = Cookies.get('adu');
    if (userStr) return JSON.parse(userStr);
    return null;
}

// return the token from the Cookies
export const getToken = () => {
    const token = Cookies.get('adact');
    if (token) return token;
    return null;
}

// return the theme data from the Cookies
export const getTheme = () => {
    const theme = Cookies.get('adtp');
    if (theme) return theme;
    return null;
}

// remove the token and user from the Cookies
export const removeUserSession = () => {
    Cookies.remove('adact');
    Cookies.remove('adu');
}